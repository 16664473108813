import React, {useEffect,useContext,} from "react"
import PropTypes from "prop-types"
import Footer from "../templates/Footer/Footer";
import { FabContext } from "../context/FabContext";
import Loading from "../templates/Loading/Loading";
import Navigation from "../templates/Navigation/Navigation";
import PopOut from "../templates/Navigation/PopOut";
import COVID from "../templates/Navigation/COVID";
import "./layout.css"


// This `location` prop will serve as a callback on route change
const Layout = ({ children, location }) => {

  const{locale} = useContext(FabContext);

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }


  return (
    <div>
    <Loading />
    <ScrollToTopOnMount/>
     <COVID />
     <Navigation />
     <PopOut />
     <div className={locale} id="container" >
        {children}
       <Footer />
     </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
