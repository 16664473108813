import React, {useContext} from 'react';
import { FabContext } from "../../context/FabContext";
import './style.scss';


const COVID = () => {

    const{loading, showCovid, setCovid, expandedCovid, setExpand} = useContext(FabContext);


    return(
        <div  className={`covid  ${showCovid ? 'on' : 'off'} ${loading ? 'hide' : 'show'}  ${expandedCovid ? 'big' : 'small'}`}>
            
            <div role="button" tabIndex={0} onClick={() => setExpand(!expandedCovid)} className="covid__left left">
                <div style={{backgroundImage: `url(../images/covid.jpg`}} className="covid__left__image image"/>
                <h5 className="covid__left__flag flag">COVID-19 ADVISORY</h5>
                <h3 className="covid__left__headline headline">Appointments are conducted with all COVID-19 accommodations in mind.</h3>
                <p className="covid__left__copy copy">
                    Covid-19 has turned our world upside down, and the bridal industry is no exception. Finding the perfect wedding dress can seem overwhelming in these uncertain times because the access we had prior to the pandemic has changed for safety for all. But have no fear, wedding dress shopping can still be fun and exciting even in these crazy times!<br/><br/>
                    In this “new normal” the bridal industry has made many adjustments to provide safe shopping experiences for both brides and their employees. Many bridal salons offer virtual appointments or the options to try gowns on in the comfort and safety of your own home. In-store appointments are more staggered to limit the amount of people in the salon at one time, and require masks as well as temperature checks.<br/> <br/>
                    As your partner in this endeavor, I will make sure you have the best shopping experience, and most importantly make sure you feel safe while doing so. Having strong connections with different designers and salons I can assure you they are doing everything possible to protect their brides. 
                </p>
                <svg className="covid__left__plus plus" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 329 329" ><path transform="rotate(-45.001 164.499 164.503)" className="st0" d="M-55.9 152.3h440.8v24.5H-55.9z"/><path transform="rotate(-45.001 164.499 164.503)" className="st0 plus" d="M152.3-55.9h24.5v440.8h-24.5z"/></svg>
            </div>

            <div
                role="button" 
                tabIndex={1}
                onClick={() => setCovid(!showCovid)}
                className="covid__close close-button" >
                    <svg className="close" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 329 329" ><path transform="rotate(-45.001 164.499 164.503)" className="st0" d="M-55.9 152.3h440.8v24.5H-55.9z"/><path transform="rotate(-45.001 164.499 164.503)" className="st0" d="M152.3-55.9h24.5v440.8h-24.5z"/></svg>
            </div>
       
        </div>
    )
}
export default COVID;
