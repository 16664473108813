import React, {useState} from 'react';
import * as emailjs from 'emailjs-com';
import './style.scss';

const Footer = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [message, setMessage] = useState("");
    const [showForm, setForm] = useState(true);

    function handleSubmit() {

        let templateParams = {
          from_name: name,
          from_email: email,
          to_name: 'farrah@fabridalstyling.com',
          subject: 'Message from Client',
          message: message,
          date: date,
         }
         emailjs.send(
             'service_oluz5hn',
             'fab_styling_email',
              templateParams,
            'user_WSuFNsPbHJyaPjAEKhTsA'
            ).then(res => { setForm(false) })
             .catch(err => console.error('message failed to send:', err))
    
     }
    
        

    return(
        <div  id="contact" className="footer">
            <a rel="noreferrer" target="_blank" href="https://www.jamielevinephotography.com/">
                <div className="footer__jamie">
                    <div className="footer__jamie__left"/>
                    <div className="footer__jamie__text">
                        <h5 className="footer__jamie__text__flag">NEED A PHOTOGRAPHER?</h5>
                        <h4 className="footer__jamie__text__headline">Jamie Levine is our<br/>preferred partner</h4>
                    </div>
                </div>
            </a>

            <div className="footer__contact">
                    <div className="footer__contact__intro">
                            <a rel="noreferrer" target="_blank" href="https://www.theknot.com/marketplace/redirect-2045695?utm_source=vendor_website&utm_medium=banner&utm_term=49f8d4a4-e8bd-483c-957e-11ecf47304a3&utm_campaign=vendor_badge_asset"><img alt="the knot link" className="footer__contact__intro__logo" src='../images/footer-theknot.png' /></a>
                            <a target="_blank" rel="noreferrer" href="https://www.weddingwire.com/biz/fab-styling/63eb560b4b96995d.html"><img alt="wedding wire link" className="footer__contact__intro__logo2" src='../images/footer-ww.png' /></a>
                            <hr className="footer__contact__intro__line" />
                            <h4 className="footer__contact__intro__headline">We'd love to<br/>hear from you</h4>
                    </div>
                    <div className="footer__contact__form">
                        <div className="footer__contact__form__center">

                            <div className="footer__contact__form__center__top">

                                    <a href="mailto:farrah@fabridalstyling.com">
                                        <h5 rel="noreferrer" className="footer__contact__form__center__top__flag">CONTACT US</h5>
                                        <h3 rel="noreferrer" className="footer__contact__form__center__top__email"> farrah@<br/>fabridalstyling.com </h3>
                                    </a>

                                    <div className="social">
                                        <a rel="noreferrer" aria-label="Facebook" role="button" href="https://www.facebook.com/fabridalstyling"  target="_blank" className="fb" />
                                        <a rel="noreferrer" aria-label="Instagram" role="button" href="https://www.instagram.com/fabridalstyling/" target="_blank" className="ig" />
                                    </div>

                            </div>

                            {
                            showForm ?
                            <div className="footer__contact__form__center__bottom">
                            <h5 className="footer__contact__form__center__bottom__flag">OR USE OUR FORM</h5>
                            <input 
                                type="text" name="name" placeholder="Your Name"  autoComplete="off"
                                className="footer__contact__form__center__bottom__name"
                                onChange={(e) => setName(e.target.value)} />
                            <input 
                                type="text" name="email" placeholder="Your Email"  autoComplete="off"
                                className="footer__contact__form__center__bottom__email"
                                onChange={(e) => setEmail(e.target.value)} />
                            <input 
                                type="date" name="date" placeholder="What's Your Date?"  autoComplete="off"
                                className="footer__contact__form__center__bottom__date"
                                onChange={(e) => setDate(e.target.value)} />
                            <textarea 
                                type="text" name="message" placeholder="Tell us a little about yourself"  autoComplete="off"
                                className="footer__contact__form__center__bottom__message"
                                onChange={(e) => setMessage(e.target.value)} />

                            <button onClick={() => handleSubmit()} className="footer__contact__form__center__bottom__submit" >Submit</button>
                            </div>
                            :
                            <div className="footer__contact__form__center__bottom">
                                <h3 className="footer__contact__form__center__bottom__thanks">Thanks for your message!</h3>
                            </div>
                            }


                        </div>
                    </div>
            </div>
        </div>
    )
}
export default Footer;
