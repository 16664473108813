import React, {useContext} from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FabContext } from "../../context/FabContext";
import './style.scss';


const PopOut = () => {
    const{showNav,setShowNav, locale} = useContext(FabContext);

    return(
        <div 
            role="table"
            onClick={() => setShowNav(!showNav)}
            className={`popOut ${locale} ${showNav ? 'show' : 'hide'}`}>
           
           <svg className="close" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 329 329" ><path transform="rotate(-45.001 164.499 164.503)" className="st0" d="M-55.9 152.3h440.8v24.5H-55.9z"/><path transform="rotate(-45.001 164.499 164.503)" className="st0" d="M152.3-55.9h24.5v440.8h-24.5z"/></svg>
            <ul className="opts popOut__options">
                <li id="home"><AniLink cover direction="right" bg="#ffffff" duration={1} to="/">Home</AniLink></li>
                <li id="exp"><AniLink cover direction="right" bg="#ffffff" duration={1} to="/experiences">Experiences</AniLink></li>
                <li id="brides"><AniLink cover direction="right" bg="#ffffff" duration={1} to="/brides">Brides</AniLink></li>
                <li id="about"><AniLink cover direction="right" bg="#ffffff" duration={1} to="/about">About</AniLink></li>
                <li id="test"><AniLink cover direction="right" bg="#ffffff" duration={1} to="/testimonials">Testimonials</AniLink></li>
            </ul>
        </div>
    )
}
export default PopOut;
